<template>
  <div 
    v-if="categoryOptions && categoryOptions.length"
    class="categories-menu-container"
  >
    <ul class="categories-menu">
      <li 
        v-for="(item, i) in categoryOptions"
        :key="i"
        class="categories-menu__item desc" 
        @click="$parent.setProductCategory(item)"
      >
        <b>
          {{ item }}
        </b>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
	name: 'Categories',
	props: [],
	data: function() {
		return {
			categoryOptions: ''
		}
	},
	mounted: function() {
		this.getCategoryOptions();
	},
	methods: {
		getCategoryOptions() {
			this.$http.get(process.env.VUE_APP_API + 'items/filter-data')
			.then((res) => {
				let newCategories = res.data.payload.types;
				this.categoryOptions = newCategories;
			})
			.catch(() => {
			})
		},
		setProductCategory(category) {
			this.$emit('setProductCategory', category)
		},
	}
}
</script>
