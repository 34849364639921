<template>
  <header class="header">
    <div class="wrapper">
      <div class="left">
        <div
          v-if="$route.name == 'Home'" 
          class="desc"
          @click="setProductCategory('all')"
        >
          <b>
            {{ $t('Buy skins') }}
          </b>
        </div>
        <router-link 
          v-if="$route.name != 'Home'" 
          to="/" 
          class="logo"
        >
          <img 
            src="./../img/logoMini.svg" 
            class="img"
          >
        </router-link>
        <div 
          v-if="$route.name != 'Home'" 
          class="input-container"
        >
          <input 
            v-model="search" 
            type="text" 
            placeholder="Search" 
            @keyup.enter="onEnter"
          >
          <img 
            src="./../img/glass.svg" 
            class="img" 
            @click="onEnter"
          >
        </div>
      </div>
      <div class="center">
        <router-link 
          v-if="$route.name == 'Home'" 
          to="/" 
          class="logo"
        >
          <img 
            src="./../img/logo.svg" 
            class="img"
          >
        </router-link>
      </div>
      <div class="right">
        <div
          v-if="$route.name != 'Home'" 
          class="desc"
          @click="setProductCategory('all')"
        >
          <b>
            {{ $t('Buy skins') }}
          </b>
        </div>
        <div 
          v-if="curr"
          class="select-container" 
        >
          <Select2 
            v-model="curr"
            class="select2 single currency-select" 
            :placeholder="curr"
            :options="$parent.currencyOptions"
            @select="selectCurr($event)"
          />
        </div>
        <div 
          v-if="currentLanguage"
          class="select-container language-select-container" 
        >
          <Select2 
            v-model="language"
            class="select2 single currency-select" 
            :options="languages"
            :placeholder="currentLanguageName"
            @select="selectLanguage($event)"
          />
        </div>
        <div 
          v-if="$parent.isAuth"
          class="cash" 
        >
          <img 
            src="./../img/wallet.svg" 
            class="img"
          >
          <div class="desc">
            <span class="currency">{{ $parent.currency }}</span>
            {{ balance }}
          </div>
          <button 
            class="button blue" 
            @click="$parent.openTopup"
          >
            <img 
              src="./../img/plus.svg" 
              class="img"
            >
          </button>
        </div>
        <router-link 
          v-if="$parent.isAuth"
          to="/profile/settings" 
          class="button link-container dropdown-open round"
        >
          <img 
            src="./../img/user.svg" 
            class="img"
          >
        </router-link>
        <button 
          v-if="$parent.isAuth" 
          :class="['icon icon-cart round', {'gray': !cartContents.length}]"
          @click="$parent.openCart" 
        >
          <img 
            src="./../img/cart.svg" 
            class="img"
          >
          <div 
            v-if="cartContents.length" 
            class="indicator"
          >
            {{ cartContents.length }}
          </div>
        </button>
        <div 
          v-else
          class="link-container" 
        >
          <div 
            class="button" 
            @click="openSignIn"
          >
            <span>{{ $t('Sign In') }}</span>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>

import Select2 from 'vue3-select2-component';
import {mapState, mapMutations, mapGetters, mapActions} from "vuex";
export default {
	name: 'Header',
  components: {
    Select2
  },
  props: {
    cartContents: {
      type: [Array, String],
      required: true
    },
    currencyCode: {
      type: String,
      required: true
    },
    searchedProductText: {
      type: String,
      required: true
    },
    isAuth: {
      type: Boolean,
      required: true
    },
    appBalance: {
      type: [Number, String],
      required: true
    },
    
  },
  
	data: function() {
		return {
      search: '',
      curr: '',
      balance: '0.00'
		}
	},
  computed: {
    ...mapState({
      languages: ({app: {languages}}) => languages.map(({id, title: text}) => ({id, text})),
      currentLanguage: state => state.app.currentLanguage,
    }),
    ...mapGetters('app', ['currentLanguageName']),
    language: {
      set(langId) {
        this.changeLocale(langId);
      },
      get() {
        return this.currentLanguage;
      }
    }
  },
  watch: {
    currencyCode(newValue) {
      this.curr = newValue;
      this.getBalance();
    },
    searchedProductText: function(newVal) {
      this.search = newVal;
    },
    isAuth() {
			this.getBalance();
		},
    appBalance() {
			this.getBalance();
		},
  },
  mounted() {
    this.curr = this.currencyCode
    this.getBalance();
  },
	methods: {
    getBalance() {
      if (this.$parent.isAuth) {
        this.$http.get(process.env.VUE_APP_API + 'user/profile')
        .then((res) => {
            this.balance = res.data.balance;
        })
        .catch((res) => {
          if(res.response.status === 401) {
            this.$parent.openSignIn();
          }
        })
      }
    },
    searchSubmit() {
      this.$emit('searchSubmit', this.search)
    },
    onEnter() {
      this.searchSubmit();
    },
    selectCurr(event) {
      this.curr = event.text;
      this.changeCurrency(event.text)
      localStorage.setItem("currency", event.text);
    },
    selectLanguage(event) {
      this.changeLocale(parseInt(event.id));
    },
    changeCurrency(item) {
      this.$emit('changeCurrency', item)
    },
    openSignIn() {
      this.$parent.openSignIn();
    },
    setProductCategory(category) {
      this.$emit('setProductCategory', category)
    },
    ...mapMutations('app' , ['setCurrentLanguage']),
    ...mapActions('app', ['changeLocale'])
	}
}
</script>
